import React from "react";
import { useState, useEffect } from "react";
import '../src/person_utils'
import { personRelativesList } from "../src/person_utils";
import { useSearchParams, Link } from 'react-router-dom';
import { treeReference, treeOrderedReference, pictureReference, getPictureReference, getPictureTinyReference } from '../firebase/storage.js'
import { getDownloadURL } from "firebase/storage";

//take in json object of person data, output array of [[category name, string information], ...] for each applicable category
function formatPersonInformation(data, orderedTreeJsonData){
  const italic_style = {
    fontStyle: 'italic'
  };
  
  var information_list = [];

  //Name info
  var name = 
  <>
    {(data["data"]["first name"] || "?") + " "}
    {data["data"]["nickname"] ? '"' + data["data"]["nickname"] + '" ' : ""}
    {data["data"]["last name"] || "?"}
    <span style={italic_style}>{data["data"]["nee"] ? " (nee " + data["data"]["nee"] + ")" : ""}</span>
  </>;
  information_list.push(["Name", name]);

  var personal_data_list = ["Birthday", "Birthplace", "Deathplace", "Other info", "Gender"];

  personal_data_list.forEach(element => {
    if (data["data"][element.toLowerCase()]){
      information_list.push([element, data["data"][element.toLowerCase()]]);
    }
  });

  var relatives_list = personRelativesList(data['id'], orderedTreeJsonData);
  information_list = information_list.concat(relatives_list);

  return information_list;
}


export default function Person() {
  const [searchParams] = useSearchParams();
  var person_id = searchParams.get('id');


  //format the person's information
  const [information_list, set_information_list] = useState([]);
  const [pictureJsonData, set_pictureJsonData] = useState([]);
  const [pictures, set_pictures] = useState([]);
  const [avatar_loc, set_avatar_loc] = useState([]);

  useEffect(() => { 
    Promise.all([
      getDownloadURL(treeReference),
      getDownloadURL(treeOrderedReference),
      getDownloadURL(pictureReference)
    ]).then(urls => 
      Promise.all(urls.map(url => fetch(url)))
    ).then(responses =>
      Promise.all(responses.map(response => response.json()))
    ).then(data => {

      let treeJsonData = data[0];
      let orderedTreeJsonData = data[1];
      let pictureJsonData = data[2];
      let cur_info_list = [];

      for (var i = 0; i < treeJsonData.length; i++) {
        if (treeJsonData[i]['id'] === person_id){
            //found the right image data
            cur_info_list = formatPersonInformation(treeJsonData[i], orderedTreeJsonData);
    
            //get the avatar pic
            if (treeJsonData[i]['data']['avatar']){
              getDownloadURL(getPictureReference(treeJsonData[i]['data']['avatar'])).then(url => set_avatar_loc(url));
            }
        }
      }
    
      //get a list of all pictures the person is in
      set_pictureJsonData(pictureJsonData);
      set_information_list(cur_info_list);
    }
    ).catch(err =>
      console.log(err)
    );
  }, [person_id]);


  useEffect(() =>{
    var promise_array = pictureJsonData.map(pictureJson => {
      if (pictureJson['people'].some(row => row.includes(person_id))){
        return getDownloadURL(getPictureTinyReference(pictureJson['filename']))
                .then(url => 
                  <Link key={"pic_" + pictureJson['filename']} 
                    to={{ pathname: '/picture', search: "name=" + pictureJson['filename'] }}>
                      <img src={url} width="150px" alt="Not found" />
                  </Link>);
      }
      return <></>;
    });

    Promise.all(promise_array).then(pics => set_pictures(pics));
    
  }, [pictureJsonData, person_id]);


  return (
    <div>
    <style>{`
      table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px;
      }
    `}</style>
    <img src={avatar_loc} width="30%" alt="No Avatar" />
    <br/>
    <table>
      <thead>
      </thead>
      <tbody>
      {information_list.map((information_row, index) => (
          <tr key={index}>
              <td>{information_row[0]}</td>
              <td>{information_row[1]}</td>
          </tr>
      ))}
      </tbody>
    </table>
    <br/><a href={process.env.PUBLIC_URL + "#/family-tree?id=" + person_id}>Link to position on family tree</a><br/>
    <br/>Pictures:<br/>
    {pictures}
    </div>
  );
}