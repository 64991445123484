import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select'
import Radium from 'radium'
import f3 from "./family-chart/index.js"; // npm i family-chart
import "./family-chart.css"; // create file 'family-chart.css' in same directory, copy/paste css from examples/create-tree
import { treeReference, getPictureTinyReference } from '../firebase/storage.js'
import { getDownloadURL } from "firebase/storage";

let people_options = [{value: '',label: ''}];

function processData(jsonData) {
  const result = Object.keys(jsonData).map((key) => { 
    return jsonData[key];
  });
  return result;
}

class FamilyTree extends React.Component {
  cont = React.createRef();

  constructor(props) {
    super(props)
    this.state ={
      store: null,
      view: null,
      treeJsonData: {},
      avatarUrls: {},
    }
  }

  componentDidMount() {
    getDownloadURL(treeReference)
      .then(url => fetch(url))
      .then(response => response.json())
      .then(data => {
        let processedData = processData(data)

        var promise_array = processedData.map(d => { 
          if(d['data']['avatar']){
            return getDownloadURL(getPictureTinyReference(d['data']['avatar']));
          }
          return '';
        });

        Promise.all(promise_array).then((urls) => {
          urls.forEach((d, i) => {
            processedData[i]['data']['avatar_url'] = d;
          });

          this.setState({
            treeJsonData: processedData
          }, () => {
            this.renderTree();
            people_options = this.state.treeJsonData.map((person) => ({
              value: person['id'],
              label: `${(person["data"]["first name"] || "?") + " "}\
                      ${person["data"]["nickname"] ? '"' + person["data"]["nickname"] + '" ' : ""}\
                      ${person["data"]["last name"] || "?"}`
            }));
          });
        });
      });
  }


  // componentDidMount() {
  //   getDownloadURL(treeReference)
  //   .then((url) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.responseType = 'json';
  //     xhr.onload = (event) => {
  //       //create avatar urls
  //       let treeJsonData = xhr.response;
  //       treeJsonData.forEach(d => {
  //         if(d['data']['avatar']){
  //           d['data']['avatar_url'] = getDownloadURL(getPictureTinyReference(d['data']['avatar']));
  //         }
  //       });

  //       this.renderTree(treeJsonData);

  //       people_options = treeJsonData.map((person) => ({
  //         value: person['id'],
  //         label: `${(person["data"]["first name"] || "?") + " "}\
  //                 ${person["data"]["nickname"] ? '"' + person["data"]["nickname"] + '" ' : ""}\
  //                 ${person["data"]["last name"] || "?"}`
  //       }));
  //     };
  //     xhr.open('GET', url);
  //     xhr.send();
  
  //   })
  //   .catch((error) => {
  //     // Handle any errors
  //   });
  // }
  
  renderTree() {
    if (!this.cont.current) return;

    const store = f3.createStore({
        main_id: this.props.person_id ? this.props.person_id : "0",
        data: this.state.treeJsonData,
        node_separation: 250,
        level_separation: 110
      }),
      view = f3.d3AnimationView({
        store,
        cont: document.querySelector("#FamilyChart")
      }),
      Card = f3.elements.Card({
        store,
        svg: view.svg,
        card_dim: {
          w: 220,
          h: 70,
          text_x: 75,
          text_y: 15,
          img_w: 60,
          img_h: 60,
          img_x: 5,
          img_y: 5
        },
        card_display: [
          (d) => `${d.data["first name"] || "?"} ${d.data["last name"] || "?"}`,
          (function (d) { if (d.data["nee"]){
            return "nee " + d.data["nee"];
          }
          else {
            return "";
          }
          }),
          (d) => `${d.data["birthday"] || ""}`
        ],
        mini_tree: true,
        link_break: false,
        infoLink: true,
      });

    view.setCard(Card);
    store.setOnUpdate((props) => view.update(props || {}));
    store.update.tree({ initial: true });
    
    this.setState({ store: store, view: view });
  }

  updateTree = (selectedOptions) => {
    let store = {...this.state.store};
    store.state.main_id = selectedOptions.value;
    this.setState({ store: store });

    this.state.store.update.tree();
  }

  focusTree = () => {
    this.state.view.update(this.props, 'main_to_middle');
  }
  defocusTree = () => {
    this.state.view.update(this.props, 'fit');
  }
  
  
  render() {
    const styles = {
      options: {
        float: "left",
        width: "20%",
        margin: "5px"
      },
      tree: {
        float: "left"
      },
      button: {
        margin: '5px',
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        borderColor: '#000000',
        ':hover': {
          backgroundColor: 'green' 
        }
      }
      
    };

    return (
    <>
      <div style={styles.options}>
        <br/>
        <div>
          <input type="checkbox" id="fit_view" name="fit_view" defaultChecked />
          <label htmlFor="fit_view">Auto-resize Tree</label>
        </div>
        <br/>
        <div ref={this.cont}>
          <label>Find Person</label>
          <br/>
          <Select options={people_options} onChange={this.updateTree} />
        </div>
        <br/>
        <div>
          <button type="button" key="button_focus" style={styles.button} onClick={this.focusTree}>View Selected Person</button>
          <button type="button" key="button_defocus" style={styles.button} onClick={this.defocusTree}>Expand to Fit Tree</button> 
        </div>
      </div>
      <div style={styles.tree}>
        <br/>
        <div className="f3" id="FamilyChart" ref={this.cont}></div>
      </div>
    </>
    );
  }
}

export default Radium(FamilyTree);