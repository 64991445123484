import { getStorage, ref, connectStorageEmulator } from "firebase/storage";

const storage = getStorage();
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  // Point to the Storage emulator running on localhost.
  connectStorageEmulator(storage, "127.0.0.1", 9199);
} 

export function getPictureReference(picture_id){
  return ref(storage, 'images/thumbnails/' + picture_id + '.png');
}
export function getPictureTinyReference(picture_id){
  return ref(storage, 'images/tiny/' + picture_id + '.png');
}
export function getPictureLargeReference(picture_id){
  return ref(storage, 'images/' + picture_id + '.png');
}
export function getPictureLabelReference(picture_id){
  return ref(storage, 'images/labels/' + picture_id + '.txt');
}

const treeReference = ref(storage, 'family_tree.json');
const treeOrderedReference = ref(storage, 'family_tree_ordered.json');
const pictureReference = ref(storage, 'picture_index.json');

export { treeReference, treeOrderedReference, pictureReference };